import React, { useState } from 'react'
import { withFB } from '../Firebase/firebasecontext';
import { MainWrapper, StyledButton } from '../Form/SharedStyledComponents';
import Login from '../LoginOld';
import * as INITFORM from '../Form/initialforms'
import { CircularProgress} from '@material-ui/core'

const PortalBase = (props) => {
    INITFORM.LI.email = props.usermail
    const [formLI, setFormLI] = useState(INITFORM.LI);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onLoginButtonClicked = (form) => {
        setFormLI(form);
        setIsLoading(true)

        props.firebase.doSignInWithEmailAndPassword(form.email, form.password)
            .then(() => props.firebase.doRedirectToCustomerPortal())
            .catch(err => {
                setIsLoading(false);
                setError(err.message);
            });
    }

    return (
        <>
            <MainWrapper>
                <Login loginButtonClicked={onLoginButtonClicked} form={formLI} firebase={props.firebase} emailfromurl={true} />
                <div style={{ color: 'red', textAlign: 'center'}}>
                    {error !== '' && <p>Error: {error}</p>}
                </div>
                {isLoading && <CircularProgress/>}
            </MainWrapper>
        </>
    )
}

const PortalPage = withFB(PortalBase);

export default PortalPage;