import styled from "styled-components"

export const MainWrapper = styled.div`
  display: flex;
  margin: 2rem 0 0;
  justify-content: center;
  align-items: center;
  flex-direction:column;

  @media (max-width: 768px) {
    margin: 1rem 0.25rem 0;
  }
  &.hidden{
    display: none;
  }
`

export const SecondaryWrapper = styled.div`
  display: flex;
  margin: 2rem 0 0;
  justify-content: center;
  align-items: center;
    flex-direction:column;
  @media (max-width: 768px) {
    margin: 2rem 1rem 0;
  }
  &.hidden{
    display: none;
  }
`

export const StyledForm = styled.form`

  max-width: 40rem;
  display:flex;
  flex-direction:column;
  padding: 1rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  margin: 1rem 0;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`
export const StyledSubTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin: 0.5rem 0;
  @media (max-width: 768px) {
    align-self: flex-start;
  }
`

export const StyledInput = styled.input`
  margin: 1rem 0;
  width: 20rem;
  height: 2rem;
  padding: 0.5rem 0.5rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StyledSelect = styled.select`
  margin: 0.5rem 0;
  width: 20rem;
  height: 2rem;
  padding-left: 0.5rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StyledButton = styled.button`
  margin: 1rem 0;

  width: 20rem;
  padding: 6px 24px;
  font-size: 1rem;

  border-radius: 4px;
  background-color: var(--primary-low);
  border: none;
  align-self: center;
  justify-self: center;
  &:hover {
    background-color: var(--primary-medium);
    border-color: var(--primary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--primary-high);
    border-color: var(--primary-high);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StyledConditionWrapper = styled.div`
  max-width: 30rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.1rem 0;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0.2rem 1rem;
  }
`

export const StyledLabel = styled.label`
  margin-left: 0.5rem;
  margin-bottom: 0;
`
export const StyledCheckbox = styled.input`
  width: 16px;
  height: 16px;
  min-width: 16px;
`