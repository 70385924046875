import React from 'react'

import LayoutOB from '../../components/layoutob'
import { useLocation } from '@reach/router'

import PortalPage from '../../components/Portal'


/**
 * Description of Checkout Functional component
 * @returns Returns the form for users that were redirected from the app and already have an account, wrapped by the OB OnBoarding Layout which contains firebase and authentication.
 */
export default function Home() {
    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var usermailParam = searchParams.get("email");

    // location="CH" is a workaround

    return (
        <LayoutOB location="CH" language="de-CH">
            <PortalPage usermail={usermailParam} />
        </LayoutOB>
    )
}