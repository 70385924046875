import React, { useEffect, useState } from "react"
import * as CONST from '../Form/formconstants'
import "../../components/globalStyles.css"
import { MainWrapper, StyledForm, StyledTitle, StyledButton } from "../Form/SharedStyledComponents"
import { TextField, Typography, withStyles } from "@material-ui/core"

const StyledTextField = withStyles({
    root: {
        margin: "0.5rem 0",
        width: "100%",
    }
})(TextField)

const ErrorTypography = withStyles({
    root: {
        color: "#FF3333",
        fontSize: '0.8rem',
        textAlign: 'center'
    }
})(Typography);

const ResetTypography = withStyles({
    root: {
        color: "#3333FF",
        fontSize: '0.8rem',
        textAlign: 'center'
    }
})(Typography);

const mailRegex = new RegExp(/.+@.+\..+/);

const errorValid = {
    email: '',
    password: '',
};


export default function Login(props) {
    const [form, setForm] = useState(props.form);
    const [isFormValid, setIsFormValid] = useState('false');
    const [error, setError] = useState('');
    const [pwdResetMsg, setPwdResetMsg] = useState('');

    useEffect(() => {
        setIsFormValid(
            mailRegex.test(form.email) &&
            form.password !== '')
    }, [form, error, pwdResetMsg])

    const onSubmitForm = (e) => {
        e.preventDefault();
        setError('');

        if (isFormValid) {
            const alreadyLoggedIn = props.firebase.isUserLoggedIn();
            if (!alreadyLoggedIn) {
                props.firebase.doSignInWithEmailAndPassword(form.email, form.password)
                    .then(() => props.loginButtonClicked(form))
                    .catch(err => {
                        if (err.code === CONST.ERROR_WRONG_PASSWORD) {
                            setError(CONST.ERRORMSG_WRONG_PASSWORD)
                        }
                        else if (err.code === CONST.ERROR_TOO_MANY_REQUESTS) {
                            setError(CONST.ERRORMSG_TOO_MANY_REQUESTS)
                        }
                        else if (err.code === CONST.ERROR_CUSTOMER_NOT_FOUND) {
                            setError(CONST.ERRORMSG_CUSTOMER_NOT_FOUND)
                        }
                        else {
                            setError(CONST.UNKNOWN_ERROR);
                        }
                    })
            }
            else {
                props.firebase.doSignOut()
                    .then(() => {
                        props.firebase.doSignInWithEmailAndPassword(form.email, form.password)
                            .then(() => props.loginButtonClicked(form))
                            .catch(err => {
                                if (err.code === CONST.ERROR_WRONG_PASSWORD) {
                                    setError(CONST.ERRORMSG_WRONG_PASSWORD)
                                }
                                else {
                                    setError(CONST.UNKNOWN_ERROR);
                                }
                            }
                            )
                    })
                    .catch(err => {
                        setError(err.message)
                    });
            }
        }
        else {
            errorValid.email = mailRegex.test(form.email) ? '' : CONST.validEmail;
            errorValid.email = form.email !== '' ? '' : CONST.validEmpty;
            errorValid.password = form.password.length > 0 ? '' : CONST.validEmpty;
            setError(CONST.invalidForm)
        }
    }

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
        setPwdResetMsg('');
        switch (e.target.name) {
            case CONST.htmlEmail:
                errorValid.email = mailRegex.test(e.target.value) ? '' : CONST.validEmail;
                break;
            case CONST.htmlPassword:
                errorValid.password = e.target.value.length > 0 ? '' : CONST.validPassword;
                break;
            default:
                console.warn("Logic error in Login", e);
                break;
        }
    }

    const onForgotPasswordClicked = email => {
        if (errorValid.email === '') {
            setError('')
            props.firebase.doPasswordReset(form.email);
            setPwdResetMsg(CONST.PWD_RESET_MSG);
        }
        else {
            setError(CONST.ERRORMSG_ENTER_VALID_EMAIL);
        }
    }

    return (
        <>
            <MainWrapper>
                <StyledForm name="login" onSubmit={onSubmitForm}>
                    {props.emailfromurl ?
                        <StyledTitle>Bitte bestätigen Sie zur Sicherheit Ihr Passwort</StyledTitle>
                        :
                        <StyledTitle>Bitte melden Sie sich an</StyledTitle>
                    }
                    {!props.emailfromurl && <StyledTextField
                        variant="outlined"
                        name={CONST.htmlEmail}
                        maxLength={200}
                        placeholder={CONST.phEmail}
                        value={form.email}
                        onChange={onChange}
                        {...errorValid.email !== '' && { error: true, helperText: errorValid.email }}
                    />}
                    <StyledTextField
                        variant="outlined"
                        type="password"
                        name={CONST.htmlPassword}
                        minLength={8}
                        value={form.password}
                        placeholder={CONST.phPassword}
                        onChange={onChange}
                        {...errorValid.password !== '' && { error: true, helperText: errorValid.password }}
                    />
                    {error !== '' && <ErrorTypography>{CONST.error} {error}</ErrorTypography>}
                    {pwdResetMsg !== '' && <ResetTypography>{pwdResetMsg}</ResetTypography>}
                    {form.email !== '' && errorValid.email === '' &&
                        <a style={{ textAlign: 'center' }}
                            onClick={() => onForgotPasswordClicked()}>
                            {CONST.FORGOTPASSWORD}
                        </a>}
                    <StyledButton id="Btn1">Weiter</StyledButton>
                </StyledForm>
            </MainWrapper>
        </>
    )
}