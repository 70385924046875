import React from 'react'

export const urlParamSwitzerland = "ch";

// Common

/** A generic error text before the actual specific error*/
export const error = 'Fehler: ';
export const invalidForm = 'Prüfen Sie bitte die roten Felder!';
export const UNKNOWN_ERROR = 'Es ist ein unerwarteter Fehler aufgetreten';
export const errorValueNeeded ="Bitte füllen Sie dieses Feld aus";
export const errorInputInvalid ="Eingabe ungültig"
//Common consts

export const optionSwitzerland = 'Schweiz';
export const optionGermany = 'Deutschland';
export const optionNo ="Nein"
export const optionYes ="Ja"



//////////////////////////////////////
//FormSignUp.js
//////////////////////////////////////

//SignUp
export const txtWelcome="Es fehlen nur noch wenige Schritte zu Ihrem DoryGo Service.";
export const labelEmail = 'E-Mail';
export const labelPassword = "Passwort";
export const errorEmail = "E-Mail nicht korrekt"
export const errorPasswordLength = 'Mindestens 8 Zeichen';
export const errorPasswordInvalid ="Das Passwort muss mindestens 8 Zeichen lang sein, sowie mindestens einen Grossbuchstaben, Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten."
export const errorPasswordMissmatch ="Passwörter stimmen nicht überein"
export const labelPasswordRepeat = "Passwort wiederholen";
export const txtGeneralSection ="Allgemeine Angaben"
export const txtChoosePassword ="Bitte legen Sie das Passwort und E-Mail fest."
export const labelFirstName = 'Vorname';
export const labelLastName = 'Nachname';
export const labelTitle ="Anrede";
export const txtSelectCountry = 'Ich wohne in';

export const txtBirthdate = "";
export const labelBirthdate = 'Geburtsdatum';
export const errorDateMax = "Das Mindestalter für unseren Service ist 18 Jahre";
export const errorDate ="Datum ungültig"

//labels for switches
export function labelAcceptPD () { return(<div>Ich stimme der Verarbeitung der personenbezogenen Daten gemäss der <a href="https://dorygo.com/datenschutzvereinbarung"> Datenschutzerklärung</a> zu</div>)}
export function labelAcceptGC () { return(<div>Ich stimme den <a href="https://dorygo.com/app/nutzungsbedingungen"> Nutzungsbedingungen</a> zu</div>)}
export function labelAcceptNL () { return(<div>Ich möchte über Neuigkeiten und Angebote informiert werden</div>)}
export const errorAcceptedData = 'Zustimmung benötigt um fortzufahren';
export const errorAcceptedTerms = 'Zustimmung benötigt um fortzufahren';

//pharmacy
export const labelPharmacy="Ihre Apotheke"
export const txtPharmacySupport ="Bei Fragen hilft Ihnen Ihre Apotheke gerne weiter."
export const labelPharmacySwitch ="Ich möchte durch die Lünsche Apotheke beliefert werden."
export const errorAcceptedPharmacy = 'Zustimmung benötigt um fortzufahren';


//Finish Order
export const labelSalesChannel ="Wie haben Sie von uns gehört?";

export const txtExplanationStartDate ="Sie können das Datum der ersten Lieferung später festlegen."
export const labelSalesChannelOther ="Woher?"

export const labelOrderButton ="DoryGo Bestellen"




///////////////////////////////////////////////////////////
/// Form Onboarding
//////////////////////////////////////////////////////////

//intro
export const  introTitle ="Willkommen bei DoryGo!"

//medication
export const medicationTitle ="Startdatum wählen"
export const medicationExplanation ="Sie erhalten die erste Lieferung eine Woche vor dem angegebenen Datum. "
export const medicationExplanationStartNow ="Die Apotheke liefert Ihre Medikamente, sobald Sie Ihre Rezepte eingereicht haben."
export const labelFirstDelivery ="Wann möchten Sie Ihre erste Lieferung erhalten?"
export const optionASAP ="Sobald wie möglich"
export const optionLater ="Ich möchte erst meine Medikamente aufbrauchen"

//Address
export const addressTitle="Lieferadresse"
export const errorZipCodeInvalid ="Postleitzahl ungültig"


//Insurance card

export const labelCardNumber ="Kartennummer"
export const labelInsuranceName ="Name der Versicherung"
export const errorInvalidCardNumber ="Kartennummer ungültig. Die Nummer muss 20 Zeichen lang sein und darf nur Zahlen und Leerzeichen enthalten."
export const insuranceCardTitle ="Versicherungskarte"





////////////
/// formAuthorization
///////////////

export const labelPhysicianName ="Vorname Nachname"
export const labelPhysicianLocation ="Ort"










///////////
//Old
/////


//////////////////////////////////////
//CreateAccount.js
//////////////////////////////////////

export const htmlFname = "fname"
export const htmlLname = "lname";
export const htmlEmail = "email";
export const htmlPassword = "password";
export const htmlPasswordRe = "passwordrepeat";
export const htmlCountry = "country";

export const htmlAcceptPD = "acceptedPersonalData";
export const htmlAcceptGC = "acceptedGeneralConditions";
export const htmlAcceptNL = "acceptedNewsletter";
export const htmlAcqChannel = "acq_ch"


//labels

export const labelSelectCountry = 'Ich wohne in';
export const optionSelectSwitzerland = 'Schweiz';
export const optionSelectGermany = 'Deutschland';
export const phFirstname = 'Vorname';
export const phLastname = 'Nachname';
export const phEmail = 'E-Mail';
export const phPassword = "Passwort";
export const phPasswordRe = "Passwort wiederholen";


export const titleCreateAccount = "Konto anlegen";

export const generalSection = "Allgemeine Angaben";
export const createAccountButtonTxt ="Account anlegen";




//error messages /validations
export const ERROR_MAIL_ALREADY_USED = 'auth/email-already-in-use';
export const ERRORMSG_MAIL_ALREADY_USED = "Diese Mailadresse wird bereits verwendet"
export const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';
export const ERRORMSG_CODE_ACCOUNT_EXISTS = 'Das Passwort ist falsch';

export const validDate = "Bitte wählen Sie ein gültiges Datum.";
export const validFirstName = 'Bitte geben Sie Ihren Vornamen ein';
export const validLastName = 'Bitte geben Sie Ihren Nachnamen ein';
export const validEmail = 'E-Mailadresse ungültig';
export const validPassword = 'Bitte benützen Sie mindestens 8 Zeichen';
export const validCountr ='Bitte wählen Sie ein Land aus'
export const validEmpty = "Bitte füllen Sie dieses Feld aus";
export const validPasswordRepeat = 'Die Passwörter stimmen nicht überein';
export const validBirthdayMin = "Datum ungülting";
export const validStartdateMin = "Bitte wählen Sie ein Datum nach dem 12. Juli";
//export const validStartdateMin = "Bitte wählen Sie ein Datum in frühenstens 14 Tage";
export const validStartdateMax = "Das Startdatum muss in den 60 nächsten Tagen liegen"
export const validDateMax = "Das Mindestalter für unseren Service ist 18 Jahre";
export const validAcceptPD = 'Zustimmung benötigt um fortzufahren';
export const validAcceptGC = 'Zustimmung benötigt um fortzufahren';


//////////////////////////////////////
//FinishOrder.js
//////////////////////////////////////

export function labelAcceptPharmacy () { return(<div> Ich möchte von dieser Apotheke beliefert werden.</div>)}
export const htmlAcceptPharmacy ="acceptedPharmacy";
export const validAcceptPharmacy ="Zustimmung benötigt um fortzufahren";
export const htmlSalesChannel ="salesChannel";

export const htmlSalesChannelOther="salesChannelOther"
export const phSalesChannelOther ="Wie haben Sie von uns gehört?"



//////////////////////////////////////
// FormWeb.js
/////////////////////////////////7///7

export const alreadyHasAccount = 'Ich habe bereits einen Account';
export const noAccountYet = 'Ich habe noch keinen Account';

//////////////////////////////////////
// FormWithAccount.js
//////////////////////////////////////

export const forwardToCheckout = 'Bestellung wird abgeschlossen...';
export const stepperBack = 'Zurück';

//////////////////////////////////////
// Login/index.js
//////////////////////////////////////

export const ERROR_CUSTOMER_NOT_FOUND = 'auth/user-not-found'
export const ERRORMSG_CUSTOMER_NOT_FOUND = 'Es gibt keinen Benutzerdatensatz, der diesem Bezeichner entspricht. Der Benutzer wurde möglicherweise gelöscht'
export const ERRORMSG_ENTER_VALID_EMAIL = 'Bitte geben Sie eine gültige Mailadresse ein';
export const ERROR_TOO_MANY_REQUESTS = 'auth/too-many-requests';
export const ERRORMSG_TOO_MANY_REQUESTS = 'Der Zugriff auf dieses Konto wurde aufgrund vieler fehlgeschlagener Anmeldeversuche vorübergehend deaktiviert. Sie können ihn sofort wiederherstellen, indem Sie Ihr Passwort zurücksetzen, oder Sie können es später erneut versuchen.';
export const ERROR_WRONG_PASSWORD = 'auth/wrong-password';
export const ERRORMSG_WRONG_PASSWORD = 'Passwort oder E-Mail ungültig';
export const FORGOTPASSWORD = 'Ich habe mein Passwort vergessen';
export const PWD_RESET_MSG ='Sie erhalten in Kürzen per E-Mail einen Link um ein neues Passwort einzurichten.';


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Onboarding
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Medication.js

export const txtMedicationLeft ="Wann benötigen Sie die erste Medikamentenlieferung?";
export const labelMedicationLeft ="Startdatum";


//address
export const txtAddress="Lieferadresse"
export const labelAddress ="Strasse und Hausnummer"
export const labelCity="Stadt"
export const labelZipCode="Postleitzahl"
export const labelCountry="Land"
export const txtPhone ="Telefonnummer"
export const labelPhone="Telefonnummer"

//pharmacyInfo

export const txtExplanation ="Die Apotheke benötigt diese Informationen um Ihre Bestellung ausführen zu können."
export const labelGender="Geschlecht"
export const optionMale="Männlich"
export const optionFemale="Weiblich"
export const txtPregnant ="Sind Sie schwanger oder am stillen?"
export const labelPregnant ="Schwanger/Stillend?"
export const optionPregnant ="Ja, schwanger"
export const optionBreastfeeding ="Ja, am stillen"
export const txtDiseases ="An welchen Krankheiten leiden Sie?"
export const labelDiseases ="Krankheiten"
export const txtAllergies ="Haben Sie Allergien?"
export const labelAllergies ="Allergie"
export const txtWhichAllergies ="Welche?"
export const labelWhichAllergies ="Welche Allergien?"
export const txtOtherMedications ="Nehmen Sie noch weitere Medikamente, pflanzliche Mittel, Vitamine oder ähnliches ein?"
export const labelOtherMedications ="Andere Medikamente"
export const labelWhichOtherMedications ="Was?"


//Insurance card

export const txtInsuranceExplanation = "Ihre Apotheke benötigt einige Informationen zu Ihrer Versicherung, um die Medikamente und Service abrechnen zu können"




////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//old stuff


export const htmlAddStreet = "add_str"
export const htmlAddCity = "add_city";
export const htmlAddZc = "add_zc";

export const htmlCustomAcqChannel = "custom_acq_channel"



// DeliveryInfo.js

export const buttonToCheckout = 'Zur Zahlungsübersicht';






export const phAddstr = 'Strasse & Hausnummer'
export const phAddcity = 'Stadt'
export const phAddzc = 'Postleitzahl'
export const phCustomAcqChannel = "Wie haben Sie über uns gehört?"

export const subtitleDeliveryAddress = 'Lieferadresse'
export const subtitleStartWhen = 'Wann möchten Sie den Service starten?';
export const subtitleAge = "Wie alt sind Sie?"

export const validAddStr = 'Adresse ungültig';
export const validAddCity = 'Stadt ungültig';
export const validAddZc = 'Postleitzahl ungültig';

export const subtitleAcquisitionChannel = 'Wie haben Sie von uns erfahren?';

export const titleConfigureDelivery = 'Lieferung einrichten';


